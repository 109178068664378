<template>
	<div class="merchants">
		<header>
			Matches ({{count}})
		</header>
		<div class="search">
			<el-input size="small" clearable @clear="getData" @keyup.enter.native="search"
				placeholder="Search" prefix-icon="el-icon-search" v-model="keyword">
			</el-input>
			<router-link to="/addmatches"><el-button type="warning" size="small" class="Confirmbtn">+ Match</el-button></router-link>
		</div>
		<div class="main">
			<el-table v-loading="loading" element-loading-text="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :data="tableData" style="width: 100%">
				<el-table-column prop="onename" label="Match" width="200">
				  <template slot-scope="scope">
					  <div class="mitem">
						  <el-avatar size="small" :src="scope.row.oneavatar"></el-avatar>
						  <span class="down">{{scope.row.onename}}</span>
					  </div>
				  </template>
				</el-table-column>
				<el-table-column prop="twoname" label="Match" width="200">
				  <template slot-scope="scope">
					  <div class="mitem">
						  <el-avatar size="small" :src="scope.row.twoavatar"></el-avatar>
						  <span class="down">{{scope.row.twoname}}</span>
					  </div>
				  </template>
				</el-table-column>
				<el-table-column prop="date" align="center" label="Match Time/Date">
				  <template slot-scope="scope">
					  <span class="down">{{scope.row.time | formatTime}}</span>
				  </template>
				</el-table-column>
				<el-table-column prop="time" align="center" label="Created Time/Date">
				  <template slot-scope="scope">
					  <span class="down">{{scope.row.create_time | formatTime}}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" label="Match Result" width="130">
					<template slot-scope="scope">
						<span v-if="scope.row.winner" style="white-space: nowrap;">(W){{scope.row.winner}}
						</span>
						<span v-if="scope.row.winner"> <br>
							{{ scope.row.matchscoreone }} - {{ scope.row.matchscoretwo }}</span>
						<i class="el-icon-data-analysis" v-else @click="resultshow(scope.$index)"></i>
					</template>
				</el-table-column>
				<el-table-column align="center" label="Edit" width="80">
					<template slot-scope="scope">
						<i class="el-icon-edit" @click="edit(scope.$index)"></i>
					</template>
				</el-table-column>
				<el-table-column align="center" label="Delete" width="80">
					<template slot-scope="scope">
						<i class="el-icon-delete" @click="deletes(scope.$index)"></i>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @current-change="currentChange" :page-count="count / 10" small layout="prev, pager, next"
				:total="count">
			</el-pagination>
			<el-dialog
			@close="hideresult"
			  title="Tip"
			  :visible.sync="centerDialogVisible"
			  width="40%"
			  center>
			  <span style="text-align: center;display: block;">Which team won the game</span>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="selectwin(showresult.onename)"><div class="mitem"><el-avatar size="small" :src="showresult.oneavatar"></el-avatar>{{showresult.onename}}</div></el-button>
			    <el-button @click="selectwin('draw')"><div class="mitem">Draw</div></el-button>
			    <el-button @click="selectwin(showresult.twoname)"><div class="mitem"><el-avatar size="small" :src="showresult.twoavatar"></el-avatar>{{showresult.twoname}}</div></el-button>
			  </span>
			</el-dialog>
			<el-dialog
			@close="hideselectwin"
			  title="Tip"
			  :visible.sync="confirmModal"
			  width="30%"
			  center>
			  <span style="text-align: center;display: block;">Key in the number of goal for both team</span>
			  <div class="mitem" style="padding: 10px 0;justify-content: space-evenly;">
				  <div style="display: flex;width: 50%;">
					  <el-avatar size="small" :src="showresult.oneavatar"></el-avatar>{{showresult.onename}}
					</div>
				  <input type="number" min="0" step="1" v-model="onescore" placeholder="Team 1 Score" :style="scoreOneCorrect === false ? 'border-color: red;' : ''">
			  </div>
			  <div class="mitem" style="padding: 10px 0;justify-content: space-evenly;">
				<div style="display: flex;width: 50%;">
					<el-avatar size="small" :src="showresult.twoavatar"></el-avatar>{{showresult.twoname}}
				</div>
				<input type="number" min="0" step="1" pattern="\d+" v-model="twoscore" placeholder="Team 2 Score" :style="scoreTwoCorrect === false ? 'border-color: red;' : ''">
			</div>
			<br><br>
			<span style="text-align: center;display: block;">Are you sure <strong>{{winname != 'draw' ? winname + ' won' : 'draw'}}</strong>?</span>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="confirmModal = false">Cancel</el-button>
			    <el-button type="primary" @click="ConfirmWin">Confirm</el-button>
			  </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				centerDialogVisible:false,
				confirmModal:false,
				loading: false,
				keyword: '',
				tableData: [],
				count: 0,
				showresult: false,
				winname: '',
				page: 1,
				onescore: 0,
				twoscore: 0,
				scoreOneCorrect: true,
				scoreTwoCorrect: true
			}
		},
		created() {
			this.getData()
		},
		methods:{
			ConfirmWin(){
				console.log('confirmwin called')
				this.getData()
				var data = {
					token: this.$store.state.token,
					mid: this.showresult.id,
					winner: this.winname,
					onescore: this.onescore,
					twoscore: this.twoscore
				}
				if((this.onescore < 0)) {
					this.scoreOneCorrect = false
				} else {
					this.scoreOneCorrect = true
				}
				if((this.twoscore < 0)) {
					this.scoreTwoCorrect = false
				} else {
					this.scoreTwoCorrect = true
				}
				if(this.scoreOneCorrect && this.scoreTwoCorrect) {
					console.log('both correct')
					this.$axios({
						url: '/result',
						method: 'post',
						data:data
					}).then(res=>{
						if(res.data.code==200){
							this.showresult = false
							this.winname = ''
							this.confirmModal = false
							this.centerDialogVisible = false
							this.onescore = 0
							this.twoscore = 0
							this.scoreOneCorrect = true
							this.scoreTwoCorrect = true
							this.getData()
						}else{
							this.$message({
								type:'error',
								message:res.data.msg
							})
						}
					})
				}
			},
			hideresult(){
				this.showresult = false
			},
			hideselectwin(){
				this.winname = ''
			},
			selectwin(name){
				this.winname = name
				this.confirmModal = true
			},
			resultshow(index){
				let data = this.tableData[index]
				this.showresult = data
				this.centerDialogVisible = true
			},
			currentChange(e){
				this.page = e
				this.getData()
			},
			getData() {
				this.loading = true
				var query = this.$qs.stringify({
					token: this.$store.state.token,
					page: this.page,
					keyword: this.keyword
				})
				this.$axios({
					url: '/matches?' + query
				}).then(res => {
					this.loading = false
					if (res.data.code == 200) {
						this.tableData = res.data.data
						this.count = res.data.count
					}
				})
			},
			removecate(deleteindex){
				var query = this.$qs.stringify({
					token: this.$store.state.token,
					id: this.tableData[deleteindex].id
				})
				this.loading = true
				this.$axios({
					url: '/matche?' + query,
					method: 'delete',
				}).then(res => {
					this.loading = false
					if (res.data.code == 200) {
						this.tableData.splice(deleteindex, 1)
						this.count--
					} else {
						this.$message({
							type: 'error',
							msg: res.data.msg
						})
					}
				})
			},
			search() {
				this.page = 1
				this.getData()
			},
			deletes(index) {
				this.$confirm('Are you confirm delete this Match?', 'Delete Match', {
					confirmButtonText: 'Confirm',
					cancelButtonText: 'Cancel',
					showClose: false,
					confirmButtonClass: 'Confirmbtn',
					cancelButtonClass: 'Cancelbtn',
					center: true
				}).then(() => {
					console.log(index)
					this.removecate(index)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: 'Cancel delete'
					});
				});
			},
			edit(index){
				this.$router.push({
					path:'/addmatches?id='+this.tableData[index].id
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.dialog-footer{
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.merchants {
		padding: 20px;

		header {
			padding: 8px 0;
			color: #fff;
			font-size: 20px;
		}

		.mitem{
			display: flex;
			align-items: center;
			.el-avatar{
				margin-right: 10px;
			}
		}
		.search {
			display: flex;

			.el-input {
				width: 60%;
				margin-right: 20px;
			}
		}
		.main{
			text-align: right;
			padding: 20px 0;
			.down{
				color: #8e8e93;
			}
			.el-pagination{
				margin-top: 10px;
			}
		}
	}
</style>
